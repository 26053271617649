@font-face {
  font-family: "Calibri";
  src: url("assets/fonts/Calibri-LightItalic.eot");
  src: url("assets/fonts/Calibri-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/Calibri-LightItalic.woff2") format("woff2"),
    url("assets/fonts/Calibri-LightItalic.woff") format("woff"),
    url("assets/fonts/Calibri-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Calibri";
  src: url("assets/fonts/Calibri-Italic.eot");
  src: url("assets/fonts/Calibri-Italic.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Calibri-Italic.woff2") format("woff2"),
    url("assets/fonts/Calibri-Italic.woff") format("woff"),
    url("assets/fonts/Calibri-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Calibri";
  src: url("assets/fonts/Calibri-Light.eot");
  src: url("assets/fonts/Calibri-Light.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Calibri-Light.woff2") format("woff2"),
    url("assets/fonts/Calibri-Light.woff") format("woff"),
    url("assets/fonts/Calibri-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("assets/fonts/Calibri-Bold.eot");
  src: url("assets/fonts/Calibri-Bold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Calibri-Bold.woff2") format("woff2"),
    url("assets/fonts/Calibri-Bold.woff") format("woff"),
    url("assets/fonts/Calibri-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("assets/fonts/Calibri.eot");
  src: url("assets/fonts/Calibri.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Calibri.woff2") format("woff2"),
    url("assets/fonts/Calibri.woff") format("woff"),
    url("assets/fonts/Calibri.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("assets/fonts/Calibri-BoldItalic.eot");
  src: url("assets/fonts/Calibri-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/Calibri-BoldItalic.woff2") format("woff2"),
    url("assets/fonts/Calibri-BoldItalic.woff") format("woff"),
    url("assets/fonts/Calibri-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

:root {
  --blue: #17426b;
  --lightblue: #c1d9ec;
  --gray: #e5e5e5;
}

body {
  font-family: "Calibri", Arial, Helvatica, sans-serif;
  color: #1e293b;
}

h1 {
  margin: 0;
  font-size: 24px;
  line-height: 1.1667;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--blue);
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: none;
}

/* login */

.logo {
  width: 320px;
  margin: 0 auto 30px;
}

.login-holder {
  flex: 1;
}

.login {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 664px;
  padding: 20px 15px;
  margin: 0 auto;
}

.login h1 {
  text-align: center;
  margin-bottom: 24px;
}

.login-form {
  border-radius: 27px;
  padding: 40px 30px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  background: rgba(243, 243, 243, 0.2);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
}

label {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  color: var(--blue);
  font-size: 11px;
  font-weight: 400;
  line-height: 1.0909;
  letter-spacing: 0.3px;
  margin: 0 16px 14px;
}

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"],
textarea {
  display: block;
  width: 100%;
  padding: 8px 8px 8px 16px;
  border-radius: 6px;
  background: #f2f2f2;
  border: 1px solid rgba(var(--lightblue), 0.5);
  font-family: "Calibri", Arial, Helvatica, sans-serif;
  height: 48px;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
textarea:focus {
  background: #c1d9ec;
  outline: none;
}

.form-group {
  margin-bottom: 15px;
}

.login-checkbox {
  width: 50%;
  position: relative;
}

.login-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 24px;
}

.login-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0;
}

.login-checkbox input:checked ~ .checkbox-slide {
  background: #198754;
}

.login-checkbox input:checked ~ .checkbox-slide:before {
  left: 20px;
}

.login-checkbox label {
  margin: 0;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 50px;
  color: #1a1a1a;
}

.login-forget {
  width: 50%;
  padding-left: 5px;
  text-decoration: none;
  text-align: right;
}

.login-forget a {
  color: #007aff;
  font-size: 12px;
  line-height: 1.6667;
  text-decoration: none;
}

.login-forget a:hover {
  text-decoration: underline;
}
.checkbox-slide {
  width: 40px;
  height: 20px;
  border-radius: 20px;
  background: #f2f2f2;
  border: 1px solid #e5e5e5;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: background 0.3s ease-in-out;
}

.checkbox-slide:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  background: #ffffff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 1px 1px 2px -1px rgba(51, 51, 51, 0.3);
  transition: left 0.3s ease-in-out;
}

.input-holder {
  position: relative;
  margin-top: -3px;
}

.btn-icon {
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  background: none;
  padding: 0;
  width: 16px;
  height: 16px;
  border: none;
  cursor: pointer;
}

.btn-icon svg {
  fill: #4d4d4d;
}

.login .btn {
  width: 100%;
  display: block;
}

.btn {
  color: #fff;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  background: #f28e36;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 700;
  border: none;
  text-decoration: none;
  font-family: "Lato", "Calibri", Arial, Helvatica, sans-serif;
  border-radius: 8px;
  padding: 8px 15px;
  padding: 11px 19px;
  cursor: pointer;
}

.btn:hover {
  background: #eb7712;
  color: #fff;
}

.btn-blue {
  background: #24457e;
}

.btn-blue:hover {
  background: #0a3071;
}

.btn svg {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}

@media (min-width: 768px) {
  .login-form {
    padding: 92px 100px;
  }

  .logo {
    width: 561px;
    margin-bottom: 57px;
  }
}

/* header */

.header {
  padding: 14px 0;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(21, 30, 40, 0.08);
}

.header .container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.container {
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
}

.search-form {
  display: none;
}

.search-wrap {
  width: 282px;
  margin: 0 auto;
}

input[type="search"] {
  display: block;
  width: 100%;
  height: 37px;
  padding: 8px 20px;
  border: 1px solid #dfe3e8;
  background: #fff;
  border-radius: 6px;
  font-family: "Calibri", Arial, Helvatica, sans-serif;
}

.nav-opener {
  width: 47px;
  height: 47px;
  background: none;
  border: none;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.nav-opener span,
.nav-opener:before,
.nav-opener:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15%;
  right: 15%;
  height: 3px;
  background: #0c355c;
}

.nav-opener:before,
.nav-opener:after {
  content: "";
  margin-top: -8px;
}

.nav-opener:after {
  margin-top: 8px;
}

.header-area {
  width: 86px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 0 auto;
}

@media (min-width: 768px) {
  flex-basis: 86px;
}

.user-block {
  margin-left: 8px;
}

.notification__btn {
  width: 38px;
  height: 38px;
  background: none;
  border: none;
  position: relative;
}

button {
  cursor: pointer;
}

.notification__num {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  overflow: hidden;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-size: 9px;
  font-weight: 700;
  position: absolute;
  background: #dc3545;
  color: #fff;
  top: 4px;
  right: 9px;
}

.notification__btn svg {
  width: 16px;
  height: 16px;
}

.user-opener {
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
  position: relative;
  display: block;
}

.user-opener:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 2px solid rgba(233, 236, 239, 0.4);
}

@media (min-width: 768px) {
  .search-form {
    display: block;
    padding-left: 49px;
    flex: 1;
  }
}

.breadcrumbs {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  gap: 16px;
  font-size: 14px;
  line-height: 1;
  flex: 1;
  padding-right: 20px;
  color: #555555;
}

.breadcrumbs a {
  text-decoration: none;
  color: #3a81bf;
}

.breadcrumbs li {
  position: relative;
}

.breadcrumbs li:first-child:before {
  display: none;
}

.breadcrumbs li:before {
  content: "/";
  position: absolute;
  left: -8px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.breadcrumbs a:hover {
  color: #555555;
}

.journey {
  padding: 42px 0;
}

.btn-back {
  padding: 20px 0;
  text-align: right;
}
.breadcrumbs-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

@media (min-width: 768px) {
  .breadcrumbs-wrap {
    flex-direction: row;
    margin-bottom: 24px;
  }

  .btn-back {
    text-align: left;
    padding: 0;
  }
}

.btn-back {
  flex-shrink: 0;
}

.btn-back a {
  color: #17426b;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  position: relative;
  vertical-align: top;
  padding-left: 25px;
}

.btn-back svg {
  width: 15px;
  height: 15px;
  fill: #24457e;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.box {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 40px 0px rgba(21, 30, 40, 0.08);
  padding: 20px 15px 60px;
  position: relative;
}

@media (min-width: 768px) {
  .box {
    padding: 18px 20px 72px;
  }
}

.heading h1 {
  text-transform: capitalize;
  font-size: 26px;

  flex: 1;
}

@media (min-width: 768px) {
  .heading h1 {
    font-size: 32px;
  }
}

.search-holder {
  padding: 20px 0;
}

.cvs-wrap {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .heading {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 42px;
    padding: 0 15px;
  }

  .heading .search-holder {
    max-width: 282px;
    flex-basis: 282px;
    margin-left: 20px;
    padding: 0;
  }

  .cvs-wrap {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
}

.list-title {
  display: block;
  border-top: 1px solid #e2e8f0;
  border-bottom: 1px solid #e2e8f0;
  padding: 13px 17px 19px;
  color: #1e293b;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}

.acc {
  position: relative;
}

.szh-accordion__item {
  margin-bottom: 13px;
}

.acc .szh-accordion__item-btn {
  display: inline-block;
  text-decoration: none;
  background: none;
  color: var(--blue);
  border: none;
  padding: 0;
  margin: 0;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 700;
}

.acc-list {
  padding: 0;
  margin: 0;
  list-style: none;
  color: var(--blue);
  font-size: 18px;
  line-height: 1.1111;
  font-weight: 400;
  padding-top: 5px;
}

.acc-list li {
  padding: 9px 0;
}

@media (min-width: 768px) {
  .acc-list li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .acc-list li {
    padding: 10px 0;
  }
}

.acc-list .btn {
  display: block;
  width: 100%;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .acc-list {
    padding-left: 41px;
  }

  .acc-list li {
    padding-right: 50px;
    min-height: 44px;
  }

  .acc-list .btn {
    flex-shrink: 0;
    margin-top: 0;
    margin-left: 20px;
  }
}

.szh-accordion__item-btn {
  text-align: left;
}

.acc-list .acc-title {
  flex: 1;
  display: block;
}

.szh-accordion__item-heading {
  position: relative;
  padding-left: 25px;
}

@media (min-width: 768px) {
  .szh-accordion__item-heading {
    padding-left: 38px;
  }
}

.szh-accordion__item-heading:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  background-size: 19px 19px;
  transition: transform 0.3s ease-in-out;
  width: 19px;
  height: 19px;
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.32451 5.9163C2.66414 5.56049 3.2279 5.54738 3.58371 5.88701L9.29502 11.3387C9.40974 11.4482 9.59027 11.4482 9.70499 11.3387L15.4163 5.88701C15.7721 5.54738 16.3359 5.56049 16.6755 5.9163C17.0151 6.2721 17.002 6.83586 16.6462 7.17549L10.9349 12.6272C10.1319 13.3937 8.86814 13.3937 8.06511 12.6272L2.3538 7.17549C1.99799 6.83586 1.98488 6.2721 2.32451 5.9163Z' fill='%2317426B'/%3E%3C/svg%3E");
}

.szh-accordion__item-btn {
  font-family: "Calibri", Arial, Helvetica, sans-serif;
}

.szh-accordion__item--expanded .szh-accordion__item-heading:before {
  transform: translateY(-50%);
}

.szh-accordion__item-heading {
  letter-spacing: -0.5px;
}

.szh-accordion__item-content {
  transition: height 0.3s ease-in-out;
}

.btn-view-all {
  display: block;
  width: 100%;
  background: none;
  border: none;
  color: #00b0f0;
  border-top: 1px solid #e2e8f0;
  padding: 16px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-holder {
  width: 100%;
  overflow: auto;
}

.table-holder table {
  table-layout: fixed;
  width: 100%;
  text-align: left;
  min-width: 768px;
}

.table-holder table th {
  color: #1e293b;
  font-size: 14px;
  line-height: 1.3;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  padding: 16px;
  border-top: 1px solid #e2e8f0;
  border-bottom: 1px solid #e2e8f0;
}

.table-holder--noborder table th {
  border: none;
}

.table-holder table td {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.3;
  color: #3a81bf;
}

.table-holder table td:first-child {
  color: #17426b;
}

.table-holder table td a {
  text-decoration: none;
  color: #3a81bf;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.table-holder table td a.btn {
  color: #fff;
}

.table-holder table td:last-child,
.table-holder table th:last-child {
  text-align: right;
  width: 160px;
}

.table-holder table td a:hover {
  color: #00b0f0;
}

.table-holder table td a.btn:hover {
  color: #fff;
}

.company {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.company-img {
  width: 24px;
  flex-basis: 24px;
  margin-right: 16px;
}

.company-title {
  display: block;
  flex: 1;
}

@media (min-width: 768px) {
  .header-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 485px;
    flex-basis: 485px;
  }

  .header-box--big {
    width: 100%;
    flex: inherit;
    align-items: center;
    padding-top: 20px;

    gap: 20px;
  }

  .header-box--big .search-holder {
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .header-box--big {
    width: 650px;
    flex-basis: 650px;
    padding-top: 0;
    justify-content: flex-end;
  }

  .header-box--big .search-holder {
    margin-left: 20px;
  }
}

.header-box .btn-blue {
  flex-shrink: 0;
}

.heading-wrap {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.heading-img {
  width: 33px;
  margin-right: 22px;
}

.heading-img img {
  max-width: none;
  width: 100%;
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 120px;
  background: #fff;
  box-shadow: -10px -10px 40px 0 rgba(21, 30, 40, 0.08);
  border-radius: 4px;
  margin-top: 10px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.active .dropdown {
  opacity: 1;
  visibility: visible;
}

.dropdown:before {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 28px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #fff transparent;
}

.dropdown button {
  display: block;
  background: none;
  padding: 6px 15px;
  border: none;
  text-align: center;
  width: 100%;
  border-radius: 4px;
}

.dropdown button:hover {
  background: var(--blue);
  color: #fff;
}

.pagination-btns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding-top: 30px;
  gap: 30px;
}

.btn-prev,
.btn-next {
  border: 1px solid transparent;
  padding: 14px 32px;
  font-size: 18px;
  line-height: 1.3333;
  font-weight: 700;
  font-family: "Calibri", Arial, Helvetica, sans-serif;
}

.btn-prev {
  border-color: #17426b;
  background: #fff;
  color: #17426b;
}

.btn-prev:hover {
  background: #17426b;
  color: #fff;
}

.table-holder--noborder tbody {
  counter-reset: counter;
}

.table-holder--noborder tr {
  counter-increment: counter;
}

.table-holder--noborder td:first-child div {
  position: relative;
  padding-left: 30px;
}

.table-holder--noborder td:first-child .item_num {
  color: #00b0f0;
  position: absolute;
  width: 20px;
  text-align: right;
  left: 0;
  top: 0;
}

.not-found {
  color: var(--blue);
  font-size: 28px;
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

.form-file {
  position: relative;
  overflow: hidden;
}

.form-file input[type="file"] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}

.form-file input[type="file"]:hover ~ .btn-blue {
  background: #0a3071;
}

.btns-list {
  display: flex;
  gap: 10px;
}
